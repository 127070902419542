import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"

const StyledHeader = styled.h2`
  margin-top: 9rem;
`
const StyledWork = styled.ul`
list-style: none;
width: 100%;
margin: 6rem 0 6rem;

.Works-item {
  margin-top: 9rem;
}

.Works-title {
  margin-top: 3rem;
}

.Works-content {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
`;

const Work = () => (
  <StaticQuery
    query={graphql`
      query Works {
        allWordpressWpWork (sort: {
          fields: date,
          order: DESC,
        }){
          edges {
            node {
              title
              featured_media {
                localFile {
                  childImageSharp {
                    fluid(maxWidth:2800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              slug
              content
              slug
              date(formatString: "MMMM DD, YYYY")
              datetime: date(formatString: "YYYY-MM-DD hh:mm:ss")
            }
          }
        }
      }
    `}
    render={ ({allWordpressWpWork}) => {
      return (
        <Layout>
          <SEO title="Works" />
          <Header />
          <StyledHeader>Works I've played a part in</StyledHeader>
          <StyledWork>
          {allWordpressWpWork.edges.map(({node}) => {
            console.log(node);
            const {
              title,
              featured_media: {
                localFile: {
                  childImageSharp: {
                    fluid
                  }
                }
              },
              content,
              slug,
            } = node;

            return (
              <li key={slug} className="Works-item">
                <div className="Works-image">
                  <Img fluid={fluid} />
                </div>
                <div className="Works-content">
                  <h3 className="Works-title">{title}</h3>
                  <div className="Works-Description"
                    dangerouslySetInnerHTML={{
                      __html: content,
                    }}
                  />
                </div>
              </li>
            )
          })}
          </StyledWork>
        </Layout>
    )}
    }
  />

)

export default Work;
